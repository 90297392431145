import React from "react";
import PropTypes from "prop-types";
import styles from "./LinkButton.module.scss";

const Button = ({ type, children, ...props }) => {
  return (
    <button type={type} className={styles.container} {...props}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
