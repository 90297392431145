import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Link, useStaticQuery, graphql } from "gatsby";
import ContentLayout from "../primitives/ContentLayout";
import Logo from "./Logo";
import styles from "./Header.module.scss";

const cx = classNames.bind(styles);

const LogoWrapper = ({ asLink, children }) => {
  if (!asLink) {
    return <h1 className={styles.logo}>{children}</h1>;
  }
  return (
    <Link className={styles.logo} to="/" title="Retour à la page d’accueil ›">
      {children}
    </Link>
  );
};

const Header = ({ inverse, isHome = false }) => {
  const { site } = useStaticQuery(
    graphql`
      query HeaderQuery {
        site: sanitySiteConfig(_id: { eq: "configuration" }) {
          mainNav {
            slug {
              current
            }
            label
          }
        }
      }
    `
  );

  const containerClassNames = cx({
    container: true,
    inverse: inverse,
  });

  return (
    <header className={containerClassNames}>
      <ContentLayout size="large">
        <div className={styles.inner}>
          <LogoWrapper asLink={!isHome}>
            <Logo width={123} />
          </LogoWrapper>

          <nav className={styles.nav}>
            <ul>
              {site.mainNav.map(({ slug, label }) => (
                <li key={slug.current}>
                  <Link activeClassName="is-current" to={`/${slug.current}/`}>
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </ContentLayout>
    </header>
  );
};

Header.defaultProps = {
  isHome: false,
  inverse: false,
};

Header.propTypes = {
  isHome: PropTypes.bool,
  inverse: PropTypes.bool,
};

export default Header;
