import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder({
  projectId: "w3tlk96v",
  dataset: "production",
});

export function getUrlFor({ asset, crop, hotspot }) {
  const source = { asset: { _ref: asset._ref || asset._id }, crop, hotspot };
  return builder.image(source);
}
