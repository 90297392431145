import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/style.scss";

const Layout = ({ pageHeader, isHome, inverse, children }) => {
  return (
    <>
      <Header isHome={isHome} inverse={inverse} />
      <div className="page-content">{children}</div>
      <Footer />
    </>
  );
};

Layout.defaultProps = {
  isHome: false,
  inverse: false,
};

Layout.propTypes = {
  isHome: PropTypes.bool,
  inverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Layout;
