import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./SocialBar.module.scss";

const cx = classNames.bind(styles);

const SocialBar = ({ ariaPrefix, inverse }) => {
  const className = cx({
    container: true,
    inverse: inverse,
  });
  return (
    <div className={className}>
      <a
        href="https://fr.linkedin.com/company/intactile-design"
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg
          viewBox="0 0 17 17"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby={`${ariaPrefix}-linkedin-icon`}
          role="img"
        >
          <title id="contact-linkedin-icon">LinkedIn</title>
          <path d="M3.73441 17H0.335961V5.66223H3.73441V17ZM2.0158 4.18339C0.904526 4.18339 0 3.25078 0 2.09169C0 0.932602 0.904526 0 2.0158 0C3.12708 0 4.03161 0.932602 4.03161 2.09169C4.03161 3.23746 3.12708 4.18339 2.0158 4.18339ZM16.4883 17H13.1028C13.1028 17 13.1028 12.6834 13.1028 11.0447C13.1028 9.40596 12.4954 8.5 11.242 8.5C9.88521 8.5 9.16158 9.44593 9.16158 11.0447C9.16158 12.79 9.16158 17 9.16158 17H5.89236V5.66223H9.16158V7.19436C9.16158 7.19436 10.1436 5.32915 12.4825 5.32915C14.8214 5.32915 16.4883 6.79467 16.4883 9.83229C16.4883 12.8699 16.4883 17 16.4883 17Z" />
        </svg>
      </a>
      <a
        href="https://twitter.com/intactiledesign"
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg
          viewBox="0 0 21 17"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby={`${ariaPrefix}-twitter-icon`}
          role="img"
        >
          <title id="contact-twitter-icon">Twitter</title>
          <path d="M18.7895 4.22686C19.0672 10.2586 14.5626 17 6.58711 17C4.16515 17 1.91289 16.2904 0 15.0717C2.28312 15.3339 4.55082 14.7015 6.35572 13.2976C4.47369 13.2668 2.88475 12.0172 2.34482 10.3203C3.02359 10.4437 3.6715 10.4129 4.28857 10.2432C2.22141 9.82668 0.802183 7.9755 0.848462 5.98548C1.43467 6.30944 2.08257 6.49456 2.79219 6.52541C0.879307 5.24501 0.339378 2.71506 1.46551 0.786751C3.57894 3.3784 6.74138 5.09074 10.3203 5.27586C9.68785 2.59165 11.7241 0 14.5009 0C15.735 0 16.8457 0.5245 17.6325 1.35753C18.6044 1.17241 19.5299 0.802178 20.363 0.30853C20.039 1.31125 19.3603 2.15971 18.4809 2.68421C19.3448 2.57623 20.1779 2.34483 20.9492 2.00544C20.3476 2.86933 19.6225 3.62523 18.7895 4.22686Z" />
        </svg>
      </a>
      <a
        href="https://vimeo.com/intactiledesign"
        target="_blank"
        rel="noreferrer noopener"
      >
        <svg
          viewBox="0 0 19 17"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby={`${ariaPrefix}-vimeo-icon`}
          role="img"
        >
          <title id="contact-vimeo-icon">Vimeo</title>
          <path d="M18.5428 3.93156C18.4653 5.84736 17.199 8.46104 14.7567 11.7863C12.237 15.2621 10.092 17 8.34751 17C7.26208 17 6.34463 15.9463 5.60808 13.8253C5.10413 11.8821 4.6131 9.95262 4.10915 8.00946C3.55351 5.88841 2.9591 4.83472 2.32593 4.83472C2.18379 4.83472 1.70568 5.14946 0.865761 5.76525L0 4.57472C0.917451 3.7263 1.82198 2.8642 2.71359 2.01577C3.94116 0.893667 4.84569 0.305244 5.46594 0.250507C6.91319 0.0999808 7.80479 1.15367 8.12784 3.3842C8.48965 5.79262 8.73517 7.29788 8.87731 7.88631C9.29081 9.8842 9.75599 10.8968 10.247 10.8968C10.6347 10.8968 11.2162 10.2537 12.0044 8.93999C12.7797 7.63999 13.1932 6.65473 13.2578 5.97051C13.3741 4.84841 12.9477 4.28735 12.0044 4.28735C11.565 4.28735 11.0999 4.39683 10.6347 4.61578C11.5521 1.45472 13.2837 -0.0915981 15.8551 0.00419147C17.7546 0.0589284 18.6591 1.37261 18.5428 3.93156Z" />
        </svg>
      </a>
    </div>
  );
};

SocialBar.defaultProps = {
  inverse: false,
};

SocialBar.propTypes = {
  ariaPrefix: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
};

export default SocialBar;
