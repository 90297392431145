import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { get } from "lodash";
import { getUrlFor } from "../utils/imageUtils";
import defaultMetaImage from "../images/meta-image-default.png";

function Seo({ description, lang, meta, title, image }) {
  const { site, gatsbySite, home } = useStaticQuery(
    graphql`
      query siteConfigQuery {
        site: sanitySiteConfig(_id: { eq: "configuration" }) {
          title
          description
        }
        gatsbySite: site {
          siteMetadata {
            siteUrl
          }
        }
        home: sanityRoute(slug: { current: { eq: "/" } }) {
          page {
            meta {
              metaTitle
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.description;
  const defaultMetaTitle = get(home, "page.meta.metaTitle", "intactile DESIGN");
  const imagelUrl = image
    ? getUrlFor(image)
        .width(2048)
        .height(1024)
        .fit("crop")
        .url()
    : `${gatsbySite.siteMetadata.siteUrl}${defaultMetaImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={defaultMetaTitle}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: title ? `${title} | ${site.title}` : defaultMetaTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:image",
          prefix: "og: http://ogp.me/ns#",
          content: imagelUrl,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@IntactileDesign",
        },
        {
          name: "twitter:title",
          content: title ? `${title} | ${site.title}` : defaultMetaTitle,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: imagelUrl,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  title: "",
  description: "",
  lang: "fr",
  meta: [],
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;
