import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "./Logo";
import SocialBar from "./SocialBar";
import Button from "../primitives/Button";
import styles from "./Footer.module.scss";

const Footer = () => {
  const { site } = useStaticQuery(
    graphql`
      query FooterQuery {
        site: sanitySiteConfig(_id: { eq: "configuration" }) {
          footerNav {
            slug {
              current
            }
            label
          }
        }
      }
    `
  );

  return (
    <footer className={styles.container}>
      <div className={styles.right}>
        <div className={styles.rightInner}>
          <p>
            Pour recevoir nos publications, <br />
            inscrivez-vous à notre newsletter
          </p>

          <form
            className={styles.newsletterForm}
            action="https://intactile.us14.list-manage.com/subscribe/post?u=a488906fe08466d10e234984b&amp;id=69088496cc"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
          >
            <div className={styles.newsletterFormContent}>
              <div className={styles.newsletterFormField}>
                <label htmlFor="mce-EMAIL">Email</label>
                <input
                  type="email"
                  name="EMAIL"
                  placeholder="Entrez votre adresse mail"
                  id="mce-EMAIL"
                />
              </div>
              <div
                style={{ position: "absolute", left: -5000 }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_a488906fe08466d10e234984b_69088496cc"
                  tabIndex="-1"
                  value=""
                  readOnly
                />
              </div>
              <Button type="submit" name="subscribe" id="mc-embedded-subscribe">
                S’abonner
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo width={130} />
        </div>
        <div className={styles.address}>
          20 rue du Carré du Roi 34000 Montpellier FRANCE
          <div className={styles.contact}>
            <a href="mailto:contact@intactile.com">contact@intactile.com</a>
            <br />
            <span>+33 (0)4 67 52 88 61</span>
          </div>
        </div>

        <SocialBar ariaPrefix="footer" inverse />

        <div className={styles.nav}>
          {site.footerNav.map(({ label, slug }) => (
            <Link to={`/${slug.current}/`} key={slug.current}>
              {label}
            </Link>
          ))}
        </div>

        <div className={styles.copyright}>&copy; intactile DESIGN 2020</div>
      </div>
    </footer>
  );
};

export default Footer;
