import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./ContentLayout.module.scss";

const cx = classNames.bind(styles);

const ContentLayout = ({ children, size }) => {
  return (
    <div
      className={cx({
        container: true,
        isSmall: size === "small",
        isRegular: size === "regular",
        isMedium: size === "medium",
        isLarge: size === "large",
      })}
    >
      {children}
    </div>
  );
};

ContentLayout.defaultProps = {
  size: "regular",
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "regular", "medium", "large"]),
};

export default ContentLayout;
